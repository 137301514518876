<template>
  <b-modal v-if="getForm.name === 'map'" class="modal" :active.sync="getForm.show">
    <div class="modal-card" style="width: auto">
      <div class="modal-card-body">
        <div class="level">
          <div class="level-item">
            <gmap-map :center="center" :zoom="12" style="width:100%; height: 400px;" @click="addMarker">
              <gmap-marker :position="marker"></gmap-marker>
            </gmap-map>
          </div>
        </div>
        <div class="level">
          <div class="level-item">
            <div class="field">
              <input class="input" v-model="marker.lat" placeholder="خطوط الطول">
            </div>
          </div>
          <div class="level-item">
            <div class="field" lable="kkk">
              <input class="input" v-model="marker.lng" placeholder="خطوط العرض">
            </div>
          </div>
        </div>
      </div>
    </div>
    {{ showMap }}
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      center: {
        lat: 24.503418291580818,
        lng: 39.61098560292976
      },
      marker: {
        lat: 24.503418291580818,
        lng: 39.61098560292976
      }
    }
  },
  mounted () {
    this.getMap()
  },
  methods: {
    getMap: function () {
      if (navigator.geolocation) {
        // console.log('yes')
      } else {
        // console.log('no')
      }
      navigator.geolocation.getCurrentPosition(position => {
        if (this.location.lat && this.location.lng) {
          this.center = {
            lat: this.location.lat,
            lng: this.location.lng
          }
          this.marker.lat = this.location.lat
          this.marker.lng = this.location.lng
        } else {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
          this.marker.lat = position.coords.latitude
          this.marker.lng = position.coords.longitude
        }
      })
    },
    addMarker (d) {
      this.marker.lat = d.latLng.lat()
      this.marker.lng = d.latLng.lng()
      this.$parent.postData.postLocation = {
        lat: d.latLng.lat(),
        lng: d.latLng.lng()
      }
    }
  },
  props: [
    'location'
  ],
  computed: {
    ...mapGetters([
      'getForm',
      'getLoader',
      'getError'
    ]),
    showMap: function () {
      return this.getMap()
    }
  }
}
</script>
